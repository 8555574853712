import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { BookingsCreateComponent } from './components/bookings-create/bookings-create.component';
import { BookingsListComponent } from './components/bookings-list/bookings-list.component';
import { BookingsPageComponent } from './components/bookings-page/bookings-page.component';
import { RedirectComponent } from './components/redirect/redirect.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'bookings',
  },
  {
    path: 'bookings',
    component: BookingsListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'bookings/new',
    component: BookingsCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'bookings/past',
    component: BookingsListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'bookings/past/:uuid',
    component: BookingsPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'bookings/:uuid',
    component: BookingsPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'redirect',
    component: RedirectComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
