export interface Building {
  id: string;
  name: string;
  timezone: string;
}

export interface User {
  uuid: string;
  firstName?: string;
  lastName?: string;
  email?: string;
}

export enum SlotDuration {
  THIRTY = 'THIRTY',
  SIXTY = 'SIXTY',
  NINETY = 'NINETY',
  ONE_TWENTY = 'ONE_TWENTY',
}

export enum BookingStatus {
  ACTIVE = 'ACTIVE',
  REVOKED = 'REVOKED',
  HOLD = 'HOLD',
}

export interface BookableResource {
  uuid: string;
  building: Building;
  name: string;
  linkedResourceUUID?: string;
  policy: BookingPolicy;
  additionalInformation: string;

  bookableResourceStats: BookableResourceStats;
  bookingAmount?: BookingAmount;
  termsAndConditions?: TermsAndConditions;
}

export interface TermsAndConditions {
  id?: string,
  displayName?: string,
  value?: string,
}

export interface BookingAmount {
  paymentType: PaymentType;
  timeSlotAmount: number;
}

export interface BookableResourceStats {
  availableSlots: number;
  bookedSlots: number;
  totalAppointments: number;
  slotAvailableAppointments: number;
  slotAppointments: number;
}

export enum PaymentType {
  None = 'NONE',
  OneTime = 'ONE_TIME',
}

export interface BookingPolicy {
  maxSlotsPerPersonPerDay: number;
  maxAppointmentsPerSlot: number;

  minLeadDays: number;
  maxAdvanceDays: number;

  bookablePeriods: QualifiedDay[];

  slotDuration: SlotDuration;
}

export interface BookingAppointment {
  uuid: string;
  bookableResource?: BookableResource;
  user: User;
  status: BookingStatus;
  startTimeEpoch: number;
  endTimeEpoch: number;
  checkoutUrl?: string;
  checkoutExpireTime?: number;
  paymentInfo?: PaymentInfo;
}

export interface PaymentInfo {
  amountPayed: number;
  paymentDateEpoch: number;
  transactionId: string;
}

export interface QualifiedDay {
  day: DayOfWeek;
  timeIntervals: TimeInterval[];
}

export enum DayOfWeek {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY'
}

export const DaysOfWeek = [
  DayOfWeek.Monday,
  DayOfWeek.Tuesday,
  DayOfWeek.Wednesday,
  DayOfWeek.Thursday,
  DayOfWeek.Friday,
  DayOfWeek.Saturday,
  DayOfWeek.Sunday
];

// Seconds from midnight in the lock's local timezone
export interface TimeInterval {
  start: number;
  end: number;
}

export interface ResourceAppointmentSlotInfo {
  slotAvailability: AppointmentSlot[];
  slotsBooked: number;
}

export interface AppointmentSlot {
  start: number;
  end: number;
  appointmentsBooked: number;
  appointmentsAvailable: number;
  slotTimeOfDay?: SlotTimeOfDay;
}

export enum SlotTimeOfDay {
  Morning = 'Morning',
  Afternoon = 'Afternoon',
  Evening = 'Evening',
}
