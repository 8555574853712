<latch-loading [isActive]="isLoading" [formGroup]="bookingForm">
  <latch-card *ngIf="step === Step.SelectResource">
    <ng-container latchCardHeader>
      <div class="latch-card-actions">
        <button class="latch-button-icon" (click)="back()">
          <span class="latch-icon-back"></span>
        </button>
      </div>
      <div class="latch-card-title">Bookings</div>
      <div class="search-container">
        <input class="search" type="text" placeholder="Search" [formControl]="searchControl"/>
      </div>
    </ng-container>
    <ng-container latchCardContent>
      <div class="bookings-card-row">
        <latch-toggle label="Available Now" formControlName="availableNow"></latch-toggle>
      </div>
      <div class="latch-divider"></div>
      <ng-container *ngFor="let building of buildings">
        <ng-container *ngIf="buildingResources(building) as resources">
          <latch-expansion-panel class="bookings-building" [expanded]="true" *ngIf="resources.length > 0">
            <latch-expansion-panel-header>
              <button class="latch-button-icon">
                <span class="latch-icon-building"></span>
              </button>
              {{ building.name }}
            </latch-expansion-panel-header>
            <latch-list>
              <a latch-list-item *ngFor="let bookableResource of resources" (click)="resource = bookableResource">
                <div latch-list-item-title>
                  {{ bookableResource.name }}
                </div>
                <div latch-list-item-description class="price-per-slot" *ngIf="bookableResource.bookingAmount?.paymentType === PaymentType.OneTime">
                  ${{ bookableResource.bookingAmount?.timeSlotAmount }} / Time slot
                </div>
                <div latch-list-item-action class="latch-radio" [class.selected]="resource === bookableResource"></div>
              </a>
            </latch-list>
          </latch-expansion-panel>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-container latchCardFooter>
      <button class="latch-button latch-dark" (click)="setStep(Step.SelectTime)" [attr.disabled]="!resource ? true : null">Continue</button>
    </ng-container>
  </latch-card>
  <latch-card *ngIf="step === Step.SelectTime && resource">
    <ng-container latchCardHeader>
      <div class="latch-card-actions">
        <button class="latch-button-icon" (click)="back()">
          <span class="latch-icon-back"></span>
        </button>
      </div>
      <div class="latch-card-title">Select time</div>
      <div class="subtitle">
        {{ resource.name }}
        <span class="price-per-slot" *ngIf="resource.bookingAmount?.paymentType === PaymentType.OneTime">${{ resource.bookingAmount?.timeSlotAmount }} / Time slot</span>
      </div>
      <div class="latch-divider"></div>
      <div class="date-text">Time is based on the property's location.</div>
      <div class="latch-divider"></div>
      <div class="date-header">
        <div class="date-navigation">
          <button class="latch-button-icon" (click)="previous()" [attr.disabled]="isPreviousDisabled() ? true : null">
            <span class="latch-icon-arrow-left"></span>
          </button>
          {{ bookingForm.controls.date.value | date: 'EEEE, MMMM d, y' }}
          <button class="latch-button-icon" (click)="next()" [attr.disabled]="isNextDisabled() ? true : null">
            <span class="latch-icon-arrow-right"></span>
          </button>
        </div>
        <div class="week-days">
          <button class="latch-button-icon" *ngFor="let day of days"
            [class.selected]="isDaySelected(day)"
            [attr.disabled]="isDayDisabled(day) ? true : null"
            (click)="selectDate(day)">{{ abbreviateDay(day) }}</button>
        </div>
      </div>
    </ng-container>
    <ng-container latchCardContent>
      <div class="date-text" *ngIf="(slots?.length ?? 0) > 0">Select up to {{ maxSlots }} time slots on your selected day.</div>
      <div class="date-text" *ngIf="(slots?.length ?? -1) === 0">No slots available.</div>
      <latch-expansion-panel class="booking-day-divider" [expanded]="true" *ngIf="slotsExist(slots, SlotTimeOfDay.Morning)">
        <latch-expansion-panel-header>Morning</latch-expansion-panel-header>
        <div class="booking-slots">
          <ng-container *ngFor="let slot of slots">
            <ng-container *ngTemplateOutlet="latchSlot; context: { $implicit: slot, timeOfDay: SlotTimeOfDay.Morning }"></ng-container>
          </ng-container>
        </div>
      </latch-expansion-panel>
      <latch-expansion-panel class="booking-day-divider" [expanded]="true" *ngIf="slotsExist(slots, SlotTimeOfDay.Afternoon)">
        <latch-expansion-panel-header>Afternoon</latch-expansion-panel-header>
        <div class="booking-slots">
          <ng-container *ngFor="let slot of slots">
            <ng-container *ngTemplateOutlet="latchSlot; context: { $implicit: slot, timeOfDay: SlotTimeOfDay.Afternoon }"></ng-container>
          </ng-container>
        </div>
      </latch-expansion-panel>
      <latch-expansion-panel class="booking-day-divider" [expanded]="true" *ngIf="slotsExist(slots, SlotTimeOfDay.Evening)">
        <latch-expansion-panel-header>Evening</latch-expansion-panel-header>
        <div class="booking-slots">
          <ng-container *ngFor="let slot of slots">
            <ng-container *ngTemplateOutlet="latchSlot; context: { $implicit: slot, timeOfDay: SlotTimeOfDay.Evening }"></ng-container>
          </ng-container>
        </div>
      </latch-expansion-panel>
    </ng-container>
    <ng-container latchCardFooter>
      <button class="latch-button latch-dark" *ngIf="!resource.bookingAmount" (click)="setStep(Step.Summary)" [attr.disabled]="bookingForm.controls.slots.value.length > 0 ? null : true">Continue</button>
      <button class="latch-button latch-dark" *ngIf="resource.bookingAmount" (click)="setStep(Step.Summary)" [attr.disabled]="bookingForm.controls.slots.value.length > 0 ? null : true">Continue to pay</button>
    </ng-container>
  </latch-card>
  <latch-card class="summary" *ngIf="step === Step.Summary && resource">
    <ng-container latchCardHeader>
      <div class="latch-card-actions">
        <button class="latch-button-icon" (click)="back()">
          <span class="latch-icon-back"></span>
        </button>
      </div>
      <div class="latch-card-title">Booking</div>
    </ng-container>
    <ng-container latchCardContent>
      <div class="date-text">Time is based on the property's location.</div>
      <div class="latch-divider"></div>
      <latch-list>
        <latch-labeled-list-item label="Selected Booking">
          {{ resource.name }}
        </latch-labeled-list-item>
      </latch-list>
      <div class="latch-divider"></div>
      <latch-list>
        <latch-labeled-list-item label="Selected Date">
          {{ bookingForm.controls.date.value | date: 'EEEE, MMMM d, y' }}
        </latch-labeled-list-item>
      </latch-list>
      <div class="latch-divider"></div>
      <latch-list>
        <latch-labeled-list-item label="Selected Time">
          <span *ngFor="let slot of bookingForm.controls.slots.value">{{ getSlotDate(slot.start) }} – {{ getSlotDate(slot.end) }}</span>
        </latch-labeled-list-item>
      </latch-list>
      <div class="latch-divider"></div>
      <ng-container *ngIf="resource.bookingAmount?.paymentType ?? PaymentType.None !== PaymentType.None">
        <latch-list>
          <latch-labeled-list-item label="Price">
            ${{ getPrice() }}
          </latch-labeled-list-item>
        </latch-list>
        <div class="latch-divider"></div>
      </ng-container>
      <div class="text-small">
        <b>Cancellation Policy</b><br>
        You are encouraged to cancel any reservations that you will be unable to use at least 6 hours in advance on the day of the reservation so that other residents are able to use this amenity.
      </div>
    </ng-container>
    <ng-container latchCardFooter>
      <div class="terms-and-conditions" *ngIf="resource.termsAndConditions">
        <latch-checkbox label="By checking this box you agree to" labelPosition="after" formControlName="termsAndConditionsAccepted"></latch-checkbox>
        <span class="terms-and-conditions-link"><a href="#" (click)="$event.preventDefault(); openTermsAndConditions()">Terms and Conditions</a>.</span>
      </div>
      <button class="latch-button latch-dark" *ngIf="!resource.bookingAmount" (click)="createBookings()" [attr.disabled]="bookingForm.invalid ? true : null">Book ({{ bookingForm.controls.slots.value.length }} Selected)</button>
      <button class="latch-button latch-dark" *ngIf="resource.bookingAmount" (click)="createBookings()" [attr.disabled]="bookingForm.invalid ? true : null">Continue to pay ${{ getPrice() }}</button>
    </ng-container>
  </latch-card>
  <latch-bookings-terms-and-conditions *ngIf="step === Step.Terms" (back)="back()" (continue)="updateConsent()"></latch-bookings-terms-and-conditions>
</latch-loading>

<ng-template #latchSlot let-slot let-timeOfDay="timeOfDay">
  <div class="booking-slot-button"
    [class.selected]="isSlotSelected(slot)"
    [class.unavailable]="slot.appointmentsAvailable === 0"
    (click)="toggleSlot(slot)"
    *ngIf="slot.slotTimeOfDay === timeOfDay">{{ getSlotDate(slot.start) }} – {{ getSlotDate(slot.end) }}</div>
</ng-template>
