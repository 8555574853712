import { Injectable } from '@angular/core';
import { User } from '@latch/latch-web';
import { Observable } from 'rxjs';
import { UserConsentAction, UserConsentKey, UserConsentStatus } from '../model/user';
import { mockResponse } from '../utility/mock-response';
import { UserConsentResponse, UserService } from './user.service';

const user: User = {
  uuid: 'mock-user-uuid',
  anonymousUuid: 'anonymous-uuid',
};

const userConsents: Map<string, UserConsentStatus> = new Map([
  [UserConsentKey.STRIPE_TOS, UserConsentStatus.UNSET]
]);

@Injectable({
  providedIn: 'root'
})
export class MockUserService implements UserService {

  getUserConsent(key: UserConsentKey): Observable<UserConsentResponse> {
    return mockResponse(() => ({
      consentKey: key,
      status: userConsents.get(key) ?? UserConsentStatus.UNSET,
      userId: user.uuid
    }));
  }

  updateUserConsent(key: UserConsentKey, action: UserConsentAction): Observable<UserConsentResponse> {
    const status = action === UserConsentAction.ACCEPT ? UserConsentStatus.ACCEPTED : UserConsentStatus.REVOKED;
    userConsents.set(key, status);
    return mockResponse(() => ({
      consentKey: key,
      status: status,
      userId: user.uuid
    }));
  }
}
