<latch-loading [isActive]="isLoading">
  <latch-card headerTitle="Book" [class.no-border]="noBookings()">
    <ng-container latchCardHeader>
      <div class="latch-card-actions">
        <button class="latch-button-icon" (click)="back()">
          <span class="latch-icon-back"></span>
        </button>
      </div>
      <div class="latch-card-title" *ngIf="mode === Mode.Current">Bookings</div>
      <div class="latch-card-title" *ngIf="mode === Mode.Past">Past Bookings</div>
      <div class="search-container" *ngIf="!noBookings()">
        <input class="search" type="text" placeholder="Search" [formControl]="searchControl"/>
      </div>
      <div class="no-results" *ngIf="noBookings()">
        You don’t have any bookings.
        <br>
        <br>
        Do you need a space for events and activities? Tap “Add new booking” below to get started.
      </div>
    </ng-container>
    <ng-container latchCardContent>
      <ng-container *ngIf="!noBookings()">
        <div class="date-text">Time is based on the property's location.</div>
        <div class="latch-divider"></div>
      </ng-container>
      <div class="search-no-results" *ngIf="bookings?.length === 0 && !!this.searchControl.value">
        There are no results for “{{ this.searchControl.value }}”.
      </div>
      <ng-container *ngFor="let building of buildings">
        <latch-expansion-panel class="bookings-building" [expanded]="true" *ngIf="buildingBookings(building).length > 0">
          <latch-expansion-panel-header>
            <button class="latch-button-icon">
              <span class="latch-icon-building"></span>
            </button>
            {{ building.name }}
          </latch-expansion-panel-header>
          <ng-container *ngIf="filterCurrent(building, bookings) as currentBookings">
            <div class="booking-divider" *ngIf="currentBookings.length > 0">
              Current
            </div>
            <latch-list *ngIf="currentBookings.length > 0">
              <ng-container *ngFor="let booking of currentBookings">
                <ng-container *ngTemplateOutlet="latchListItemContent; context: { $implicit: booking }"></ng-container>
              </ng-container>
            </latch-list>
          </ng-container>
          <ng-container *ngIf="filterUpcoming(building, bookings) as upcomingBookings">
            <div class="booking-divider" *ngIf="upcomingBookings.length > 0">
              Upcoming
            </div>
            <latch-list *ngIf="upcomingBookings.length > 0">
              <ng-container *ngFor="let booking of upcomingBookings">
                <ng-container *ngTemplateOutlet="latchListItemContent; context: { $implicit: booking }"></ng-container>
              </ng-container>
            </latch-list>
          </ng-container>
          <ng-container *ngIf="filterPast(building, bookings) as pastBookings">
            <div class="booking-divider" *ngIf="pastBookings.length > 0">
              Past
            </div>
            <latch-list *ngIf="pastBookings.length > 0">
              <ng-container *ngFor="let booking of pastBookings">
                <ng-container *ngTemplateOutlet="latchListItemContent; context: { $implicit: booking }"></ng-container>
              </ng-container>
            </latch-list>
          </ng-container>
        </latch-expansion-panel>
      </ng-container>
      <ng-container *ngIf="mode === Mode.Current">
        <div class="booking-divider">Past</div>
        <latch-list>
          <a latch-list-item routerLink="/bookings/past">
            <div latch-list-item-title>View past bookings</div>
            <div latch-list-item-action class="latch-icon-right-arrow-blue"></div>
          </a>
        </latch-list>
      </ng-container>
    </ng-container>
    <ng-container latchCardFooter>
      <button class="latch-button latch-dark" routerLink="/bookings/new">Add new booking</button>
    </ng-container>
  </latch-card>
</latch-loading>

<ng-template #latchListItemContent let-booking>
  <a latch-list-item [routerLink]="booking.uuid">
    <div latch-list-item-title>
      {{ booking.bookableResource?.name }}
    </div>
    <div latch-list-item-description *ngIf="booking.status === BookingStatus.ACTIVE">
      {{ formatDate(booking.bookableResource?.building, booking.startTimeEpoch) }}
    </div>
    <div latch-list-item-description class="error" *ngIf="booking.status === BookingStatus.REVOKED">
      Booking canceled by Management.
    </div>
    <div latch-list-item-description class="hold" *ngIf="booking.status === BookingStatus.HOLD">
      Booking on hold
    </div>
    <div latch-list-item-action class="latch-icon-right-arrow-blue"></div>
  </a>
</ng-template>
