import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'latch-bookings-terms-and-conditions',
  templateUrl: './bookings-terms-and-conditions.component.html'
})
export class BookingsTermsAndConditionsComponent {
  @Output() back = new EventEmitter();
  @Output() continue = new EventEmitter();
}
