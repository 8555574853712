<latch-card headerTitle="Terms and Conditions" class="no-border">
  <ng-container latchCardHeader>
    <div class="latch-card-actions">
      <button class="latch-button-icon" (click)="back.emit()">
        <span class="latch-icon-back"></span>
      </button>
    </div>
    <div class="latch-card-title">Terms and Conditions</div>
  </ng-container>
  <div class="body-text" latchCardContent>
    You can book various resources within your building via Latch. Where the resource you are booking has an associated fee,
    the fee payment will be processed by Latch's payment processing partner, Stripe. By using Latch’s Payment Services, you agree
    that such usage constitutes your electronic signature, and you consent to electronic provision of all disclosures and notices
    from Latch, including those required by applicable law. You also agree that your electronic signature will have the same legal
    effect as a physical signature.
    <br>
    <br>
    By proceeding with the booking, you agree that your personal and payment information will be processed in accordance with Stripe’s
    <a href="https://stripe.com/US/connect-account/legal" target="_blank" rel="noopener noreferrer">Services Agreement</a>
    and
    <a href="https://stripe.com/US/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
    and Latch’s
    <a href="https://www.latch.com/terms-of-service" target="_blank" rel="noopener noreferrer">Terms of Service</a>
    and
    <a href="https://www.latch.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
  </div>
  <button latchCardFooter class="latch-button latch-dark" (click)="continue.emit()">Agree and Continue</button>
</latch-card>
