export interface BookingsWindow extends Window {
  webkit?: {
    messageHandlers?: {
      latchTokenMessageHandler?: MessageHandler,
      latchStatusBarMessageHandler?: MessageHandler,
      latchExternalUrlMessageHandler?: MessageHandler,
    }
  };
  latchReceiveToken?: (token: string) => void;
  latchBack?: () => boolean;
}

interface MessageHandler {
  postMessage: (message: any) => void;
}

export interface LatchAndroid {
  getToken: () => string;
  openExternalUrl: (url: string) => void;
}

export enum AppType {
  Web = 'web',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  iOS = 'ios',
  Android = 'android',
}

export enum StatusBarColor {
  Default = '',
  Green = 'GREEN',
  Black = 'BLACK',
  Grey = 'GREY',
}
