import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { LatchDialogService } from '@latch/latch-web';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { BookingAppointment, BookingStatus, Building } from 'src/app/model/bookings';
import { BookingService } from 'src/app/services/booking.service';
import { NativeAppService } from 'src/app/services/native-app.service';

function sortBookingAppointments(a: BookingAppointment, b: BookingAppointment): number {
  if (a.status !== b.status) {
    return a.status === BookingStatus.ACTIVE ? -1 : 1;
  }
  return a.startTimeEpoch - b.startTimeEpoch;
}

enum Mode {
  Current,
  Past,
}

@Component({
  selector: 'latch-bookings-list',
  templateUrl: './bookings-list.component.html',
  styleUrls: ['./bookings-list.component.scss']
})
export class BookingsListComponent implements OnInit, OnDestroy {
  bookings?: BookingAppointment[];
  searchControl = new FormControl<string | undefined>(undefined, { nonNullable: true });
  isLoading = false;
  BookingStatus = BookingStatus;
  mode = Mode.Current;
  Mode = Mode;

  private unsubscribe$ = new Subject<void>();

  constructor(
    public auth: AuthService,
    public bookingService: BookingService,
    private dialog: LatchDialogService,
    private nativeApp: NativeAppService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  get buildings(): Building[] {
    const buildings = this.bookings?.map(booking => booking.bookableResource?.building).reduce((prev, curr) => {
      if (curr) {
        const exists = prev.find(building => building.id === curr?.id);
        if (!exists) {
          prev.push(curr);
        }
      }
      return prev;
    }, [] as Building[]) as Building[];
    return buildings;
  }

  ngOnInit(): void {
    if (this.route.snapshot.routeConfig?.path === 'bookings/past') {
      this.mode = Mode.Past;
    }
    this.nativeApp.back$.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(() => this.back());

    this.searchControl.valueChanges.pipe(
      tap(() => this.bookings = undefined),
      debounceTime(250),
    ).subscribe(
      () => {
        this.handleLoadBookings(this.searchControl.value);
      }
    );
    this.handleLoadBookings(this.searchControl.value);
  }

  handleLoadBookings(searchTerm?: string): void {
    this.isLoading = true;
    this.bookingService.getBookings({ searchTerm, searchPastBookings: this.mode === Mode.Past ? true : undefined }).subscribe({
      next: bookings => {
        this.bookings = bookings;
        this.isLoading = false;
      },
      error: error => {
        this.isLoading = false;
        this.dialog.openConfirmation({
          data: {
            title: 'Error',
            messages: [error?.message ?? error],
            confirmText: 'OK',
            confirmButtonClasses: ['latch-button-link'],
            showCloseButton: false,
          }
        });
      }
    });
  }

  buildingBookings(building: Building): BookingAppointment[] {
    return this.bookings?.filter(booking => booking.bookableResource?.building?.id === building.id) ?? [];
  }

  filterCurrent(building: Building, bookings?: BookingAppointment[]): BookingAppointment[] {
    return bookings?.filter(booking =>
      moment.tz(building.timezone).isSame(booking.startTimeEpoch, 'day') &&
      moment.tz(building.timezone).isBefore(booking.endTimeEpoch) &&
      booking.bookableResource?.building?.id === building.id
    ).sort(sortBookingAppointments) ?? [];
  }

  filterUpcoming(building: Building, bookings?: BookingAppointment[]): BookingAppointment[] {
    return bookings?.filter(booking =>
      moment.tz(building.timezone).isBefore(booking.startTimeEpoch, 'day') &&
      booking.bookableResource?.building?.id === building.id
    ).sort(sortBookingAppointments) ?? [];
  }

  filterPast(building: Building, bookings?: BookingAppointment[]): BookingAppointment[] {
    return bookings?.filter(booking =>
      moment.tz(building.timezone).isAfter(booking.endTimeEpoch) &&
      booking.bookableResource?.building?.id === building.id
    ).sort(sortBookingAppointments) ?? [];
  }

  formatDate(building: Building, date: number): string {
    const dateEpoch = moment.unix(date / 1000);
    return moment.tz(dateEpoch, building.timezone).calendar( null, {
      lastDay:  '[Yesterday] – h:mma',
      sameDay:  '[Today] – h:mma',
      nextDay:  '[Tomorrow] – h:mma',
      sameElse: () => ('[' + moment.tz(dateEpoch, building.timezone).format('MMM D, YYYY – h:mma') + ']'),
    });
  }

  noBookings(): boolean {
    return this.bookings?.length === 0 && !this.searchControl.value;
  }

  back(): void {
    if (this.mode === Mode.Past) {
      this.router.navigate(['/']);
      return;
    }
    if (this.nativeApp.isRunningInApp()) {
      this.nativeApp.logout();
    } else {
      this.auth.logout({ returnTo: window.location.origin });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
