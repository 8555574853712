import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'latch-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss']
})
export class LatchExpansionPanelComponent {
  @Input()
  expanded = true;

}
