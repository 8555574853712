import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LatchDialogService } from '@latch/latch-web';
import * as moment from 'moment-timezone';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { BookingAppointment, BookingStatus, PaymentType } from 'src/app/model/bookings';
import { StatusBarColor } from 'src/app/model/native-app';
import { UserConsentAction, UserConsentKey, UserConsentStatus } from 'src/app/model/user';
import { BookingService } from 'src/app/services/booking.service';
import { NativeAppService } from 'src/app/services/native-app.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import * as ics from 'ics';

enum Step {
  Summary,
  Terms,
  Payment
}

@Component({
  selector: 'latch-bookings-page',
  templateUrl: './bookings-page.component.html',
  styleUrls: ['./bookings-page.component.scss']
})
export class BookingsPageComponent implements OnInit, OnDestroy {
  isLoading = false;
  booking?: BookingAppointment;
  BookingStatus = BookingStatus;
  Step = Step;
  step = Step.Summary;
  PaymentType = PaymentType;
  enableCalendarLink = environment.enableCalendarLink;

  private unsubscribe$ = new Subject();

  constructor(
    private bookingService: BookingService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: LatchDialogService,
    private nativeApp: NativeAppService,
    private userService: UserService
  ) { }

  get Date(): string {
    return moment(this.booking?.startTimeEpoch).format('dddd, MMMM D, YYYY');
  }

  get Time(): string {
    return `${moment(this.booking?.startTimeEpoch).format('h:mma')} – ${moment(this.booking?.endTimeEpoch).format('h:mma')}`;
  }

  getPaymentDate(): string {
    if (this.booking?.paymentInfo) {
      return `$${this.booking?.paymentInfo?.amountPayed} Paid on
        ${moment.unix(this.booking?.paymentInfo?.paymentDateEpoch).format('MMMM D, YYYY')}`;
    } else {
      return `$${(this.booking?.bookableResource?.bookingAmount?.timeSlotAmount ?? '').toString()}`;
    }
  }

  ngOnInit(): void {
    this.nativeApp.back$.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(() => this.back());
    this.nativeApp.changeStatusBarColor(StatusBarColor.Grey);

    this.isLoading = true;
    this.route.params.pipe(
      switchMap(({ uuid }) => this.bookingService.getBookingAppointment(uuid as string)),
    ).subscribe({
      next: booking => {
        this.booking = booking;
        moment.tz.setDefault(booking.bookableResource?.building.timezone);
        this.isLoading = false;
        if (this.isActive()) {
          this.nativeApp.changeStatusBarColor(StatusBarColor.Green);
        } else {
          this.nativeApp.changeStatusBarColor(StatusBarColor.Grey);
        }
      },
      error: error => {
        this.isLoading = false;
        this.dialog.openConfirmation({
          data: {
            title: 'Error',
            messages: [error?.message ?? error],
            confirmText: 'OK',
            confirmButtonClasses: ['latch-button-link'],
            showCloseButton: false,
          }
        }).afterClosed().subscribe(
          () => {
            this.router.navigate(['..']);
          }
        );
      }
    });
  }

  openCalLink(): void {

    const startTime = moment(this.booking?.startTimeEpoch);
    const endTime = moment(this.booking?.endTimeEpoch);

    const event: ics.EventAttributes = {
      title: this.booking?.bookableResource?.name ? 'Bookings for ' + this.booking?.bookableResource?.name : 'Booking',
      // Only set description if additionalInformation is non-null
      ...(this.booking?.bookableResource?.additionalInformation && { description: this.booking?.bookableResource?.additionalInformation }),
      start: [
        startTime.year(),
        // moment month() are zero indexed, so we need to increment by 1
        startTime.month() + 1,
        startTime.date(),
        startTime.hour(),
        startTime.minute(),
      ],
      end: [
        endTime.year(),
        // moment month() are zero indexed, so we need to increment by 1
        endTime.month() + 1,
        endTime.date(),
        endTime.hour(),
        endTime.minute(),
      ],
    };

    const { error, value } = ics.createEvent(event);
    if (error) {
      this.dialog.openConfirmation({
        data: {
          title: 'Error',
          messages: [error?.message ?? error],
          confirmText: 'OK',
          confirmButtonClasses: ['latch-button-link'],
          showCloseButton: false,
        }
      });
      return;
    }
    const dataURI = 'data:text/calendar;charset=utf-8,' + encodeURIComponent(<string>value);
    const link = document.createElement('a');
    link.href = dataURI;
    link.click();
  }

  ngOnDestroy(): void {
    moment.tz.setDefault();
    this.nativeApp.changeStatusBarColor();
  }

  back() {
    switch (this.step) {
      case Step.Summary:
        this.router.navigate(['..'], { relativeTo: this.route });
        break;
      case Step.Terms:
        this.setStep(Step.Summary);
        break;
    }
  }

  isActive() {
    if (this.booking) {
      return moment().isBetween(this.booking.startTimeEpoch, this.booking.endTimeEpoch);
    } else {
      return false;
    }
  }

  isPast() {
    if (this.booking) {
      return moment().isAfter(this.booking.endTimeEpoch);
    } else {
      return false;
    }
  }

  bookingTimeToExpire(date?: number): string {
    let minutesToExpire = moment.unix(date ?? 0).diff(moment(), 'minutes');
    minutesToExpire = minutesToExpire > 0 ? minutesToExpire : 0;
    return minutesToExpire.toString() + ' minutes';
  }

  setStep(step: Step) {
    if (step === Step.Payment) {
      if (this.booking?.checkoutUrl) {
        this.bookingService.payBookingAppointment(this.booking);
      } else {
        this.router.navigate(['..']);
      }
      return;
    }
    this.step = step;
  }

  goToPayment() {
    this.isLoading = true;
    this.userService.getUserConsent(UserConsentKey.STRIPE_TOS).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe({
      next: userConsentResponse => {
        this.isLoading = false;
        if (userConsentResponse.status === UserConsentStatus.ACCEPTED) {
          this.setStep(Step.Payment);
        } else {
          this.setStep(Step.Terms);
        }
      },
      error: error => {
        this.isLoading = false;
        this.dialog.openConfirmation({
          data: {
            title: 'Error',
            messages: [error?.message ?? error],
            confirmText: 'OK',
            confirmButtonClasses: ['latch-button-link'],
            showCloseButton: false,
          }
        });
      }
    });
  }

  updateConsent() {
    this.isLoading = true;
    this.userService.updateUserConsent(UserConsentKey.STRIPE_TOS, UserConsentAction.ACCEPT).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe({
      next: () => {
        this.isLoading = false;
        this.setStep(Step.Payment);
      },
      error: error => {
        this.isLoading = false;
        this.dialog.openConfirmation({
          data: {
            title: 'Error',
            messages: [error?.message ?? error],
            confirmText: 'OK',
            confirmButtonClasses: ['latch-button-link'],
            showCloseButton: false,
          }
        });
      }
    });
  }

  cancelBooking() {
    this.dialog.openConfirmation({
      data: {
        title: 'Cancel booking',
        messages: ['Are you sure you want to cancel this booking?'],
        confirmText: 'Yes, cancel',
        confirmButtonClasses: ['latch-button-link', 'latch-danger'],
        secondaryButtonText: 'No',
        secondaryButtonClasses: ['latch-button-link'],
        showCloseButton: false,
      }
    }).afterClosed().subscribe(
      shouldDelete => {
        if (shouldDelete && this.booking) {
          this.isLoading = true;
          this.bookingService.deleteBookingAppointment(this.booking.uuid).subscribe(
            () => {
              this.isLoading = false;
              this.dialog.openConfirmation({
                data: {
                  title: 'Booking canceled',
                  messages: ['Your booking has been successfully canceled.'],
                  confirmText: 'OK',
                  confirmButtonClasses: ['latch-button-link'],
                  showCloseButton: false,
                }
              }).afterClosed().subscribe(
                () => {
                  this.router.navigate(['..']);
                }
              );
            },
            error => {
              this.isLoading = false;
              this.dialog.openConfirmation({
                data: {
                  title: 'Error',
                  messages: [error?.message ?? error],
                  confirmText: 'OK',
                  confirmButtonClasses: ['latch-button-link'],
                  showCloseButton: false,
                }
              });
            }
          );
        }
      }
    );
  }
}
