import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BookableResource, BookingAppointment, ResourceAppointmentSlotInfo } from '../model/bookings';

export interface CreateBookingAppointmentInput {
  bookableResource: BookableResource;
  timeSlots: TimeSlot[];
  checkoutSuccessUrl?: string,
  checkoutCancelUrl?: string,
}

export interface TimeSlot {
  startTimeEpoch: number;
  endTimeEpoch: number;
}

export interface SearchBookingsInput {
  searchTerm?: string;
  searchPastBookings?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export abstract class BookingService {
  abstract getBookings(input: SearchBookingsInput): Observable<BookingAppointment[]>;
  abstract createBookingAppointments(input: CreateBookingAppointmentInput): Observable<BookingAppointment[]>;
  abstract getBookingAppointment(uuid: string): Observable<BookingAppointment>;
  abstract deleteBookingAppointment(uuid: string): Observable<null>;
  abstract getResources(searchTerm?: string, availableNow?: boolean): Observable<BookableResource[]>;
  abstract getResourceTimeSlots(bookableResourceUUID: string, start: number): Observable<ResourceAppointmentSlotInfo>;
  abstract payBookingAppointment(booking: BookingAppointment): Observable<null>;
  abstract getTermsAndConditionsDownloadUrl(uuid: string): Observable<string>;
}
