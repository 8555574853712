export enum UserConsentKey {
  STRIPE_TOS = 'resstripetos'
}

export enum UserConsentStatus {
  UNSET = 'UNSET',
  ACCEPTED = 'ACCEPTED',
  REVOKED = 'REVOKED'
}

export enum UserConsentAction {
  ACCEPT = 'accept',
  REVOKE = 'revoke'
}
