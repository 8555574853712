import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AuthService } from '@auth0/auth0-angular';
import { NativeAppService } from '../services/native-app.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private nativeApp: NativeAppService,
  ) { }

  public canActivate(): Observable<boolean> {
    if (this.nativeApp.isRunningInApp()) {
      // running in the web view
      return this.nativeApp.getToken().pipe(
        map(() => true)
      );
    } else {
      // running in the browser
      return this.auth.isAuthenticated$.pipe(
        switchMap(isAuthenticated => {
          if (!isAuthenticated) {
            this.auth.loginWithRedirect();
            return of(false);
          } else {
            return of(true);
          }
        }),
      );
    }
  }
}
