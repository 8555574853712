import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'latch-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {
  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    const manualredirect = this.route.snapshot.queryParams.manualredirect as string | undefined;
    if (manualredirect !== 'true') {
      this.goToUrl();
    }
  }

  goToUrl() {
    const queryRegEx = new RegExp('^(latchapp://|https?)');
    const queryURL = this.route.snapshot.queryParams.r as string | undefined;

    // Validate expected query params before re-directing
    if (queryURL?.match(queryRegEx)) {
      window.location.href = queryURL;
    }
  }
}
