import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BookableResource, BookingAppointment, ResourceAppointmentSlotInfo } from '../model/bookings';
import { BookingService, CreateBookingAppointmentInput, SearchBookingsInput } from './booking.service';
import { NativeAppService } from './native-app.service';

interface Result<T> {
  payload: {
    message: T;
  };
}

interface PagedResult<T> {
  payload: {
    message: {
      elements: T;
      nextPageToken: string,
      totalSize: number,
    };
  };
}

@Injectable({
  providedIn: 'root'
})
export class HTTPBookingService implements BookingService {

  constructor(
    private http: HttpClient,
    private nativeApp: NativeAppService,
  ) { }

  getBookings(input: SearchBookingsInput): Observable<BookingAppointment[]> {
    let params = new HttpParams();
    if (input.searchTerm) {
      params = params.append('searchTerm', input.searchTerm.trim());
    }
    if (input.searchPastBookings) {
      params = params.append('endTimeEpoch', moment().unix());
    } else {
      params = params.append('startTimeEpoch', moment().unix());
    }
    return this.http.get<Result<BookingAppointment[]>>(
      `${environment.apiBaseUrl}/app/v1/bookings/booking-appointments`,
      { params },
    ).pipe(
      map(result => result.payload.message),
      map(bookings => bookings.filter(b => input.searchPastBookings ? moment().isAfter(b.endTimeEpoch) : true)),
    );
  }

  createBookingAppointments(input: CreateBookingAppointmentInput): Observable<BookingAppointment[]> {
    if (input.bookableResource.bookingAmount?.timeSlotAmount ?? 0 > 0) {
      const checkoutUrl = this.nativeApp.createCheckoutUrl();
      input.checkoutSuccessUrl = checkoutUrl;
      input.checkoutCancelUrl = checkoutUrl;
    }
    return this.http.post<Result<BookingAppointment[]>>(
      `${environment.apiBaseUrl}/app/v1/bookings/buildings/${input.bookableResource.building.id}/` +
      `bookable-resources/${input.bookableResource.uuid}/booking-appointments`,
      input,
    ).pipe(
      map(result => result.payload.message),
      catchError((error: HttpErrorResponse) => throwError(() => error.error?.payload ?? error))
    );
  }

  getBookingAppointment(uuid: string): Observable<BookingAppointment> {
    return this.http.get<Result<BookingAppointment>>(
      `${environment.apiBaseUrl}/app/v1/bookings/booking-appointments/${uuid}`,
    ).pipe(
      map(result => result.payload.message),
    );
  }

  deleteBookingAppointment(uuid: string): Observable<null> {
    return this.http.delete<null>(
      `${environment.apiBaseUrl}/app/v1/bookings/booking-appointments/${uuid}`,
    );
  }

  getResources(searchTerm?: string, availableNow?: boolean): Observable<BookableResource[]> {
    let params = new HttpParams()
      .append('availableNow', availableNow ? 'true' : 'false');
    if (searchTerm) {
      params = params.append('searchTerm', searchTerm.trim());
    }
    return this.http.get<PagedResult<BookableResource[]>>(
      `${environment.apiBaseUrl}/app/v1/bookings/bookable-resources`,
      { params },
    ).pipe(
      map(result => result.payload.message.elements),
    );
  }

  getResourceTimeSlots(bookableResourceUUID: string, start: number): Observable<ResourceAppointmentSlotInfo> {
    const year = moment.unix(start).year();
    const month = moment.unix(start).month() + 1;
    const day = moment.unix(start).date();
    return this.http.get<Result<ResourceAppointmentSlotInfo>>(
      `${environment.apiBaseUrl}/app/v1/bookings/bookable-resources/${bookableResourceUUID}/slots/${year}/${month}/${day}`
    ).pipe(
      map(result => result.payload.message)
    );
  }

  payBookingAppointment(booking: BookingAppointment): Observable<null> {
    if (booking.checkoutUrl) {
      this.nativeApp.openUrl(booking.checkoutUrl);
      return of(null);
    } else {
      return throwError(() => new Error('No Checkout URL'));
    }
  }

  getTermsAndConditionsDownloadUrl(uuid: string): Observable<string> {
    return this.http.get<Result<{ downloadUrl: string }>>(
      `${environment.apiBaseUrl}/app/v1/bookings/store/terms-and-conditions/${uuid}`,
    ).pipe(
      map(result => result.payload.message.downloadUrl),
    );
  }
}
