import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserConsentAction, UserConsentKey, UserConsentStatus } from '../model/user';

export interface UserConsentResponse {
  consentKey: UserConsentKey;
  status: UserConsentStatus;
  userId: string;
}

@Injectable({
  providedIn: 'root'
})
export abstract class UserService {
  abstract getUserConsent(key: UserConsentKey): Observable<UserConsentResponse>;
  abstract updateUserConsent(key: UserConsentKey, action: UserConsentAction): Observable<UserConsentResponse>;
}
