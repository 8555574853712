import { Inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Auth0ClientService, AuthClientConfig, AuthHttpInterceptor, AuthState } from '@auth0/auth0-angular';
import { Auth0Client } from '@auth0/auth0-spa-js';
import { catchError, switchMap, timeoutWith } from 'rxjs/operators';
import { NativeAppService } from '../services/native-app.service';

@Injectable()
export class AuthInterceptor extends AuthHttpInterceptor {
  constructor(
    configFactory: AuthClientConfig,
    @Inject(Auth0ClientService) auth0Client: Auth0Client,
    authState: AuthState,
    private nativeApp: NativeAppService,
  ) {
    super(configFactory, auth0Client, authState);
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.nativeApp.isRunningInApp()) {
      request = this.nativeApp.addAuthentication(request);
      return next.handle(request).pipe(
        catchError((err: HttpEvent<any>) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              return this.nativeApp.getToken(true).pipe(
                switchMap(() => {
                  request = this.nativeApp.addAuthentication(request);
                  return next.handle(request);
                }),
                timeoutWith(5000, throwError(err)),
              );
            }
          }
          return throwError(err);
        })
      );
    } else {
      return super.intercept(request, next);
    }
  }
}
