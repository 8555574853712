import { HttpRequest } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LatchAndroid, BookingsWindow } from '../model/native-app';
import { AppType, StatusBarColor } from '../model/native-app';

declare const window: BookingsWindow;
declare const latchAndroid: LatchAndroid;

@Injectable({
  providedIn: 'root'
})
export class NativeAppService {
  token?: string;
  token$ = new Subject<string>();
  back$ = new Subject<void>();

  private runningInApp = false;
  private runningApp = AppType.Web;

  constructor(
    private ngZone: NgZone,
  ) {
    window.latchReceiveToken = (token: string) => {
      this.ngZone.run(() => {
        this.token$.next(token);
      });
    };
    window.latchBack = () => {
      this.ngZone.run(() => {
        this.back$.next();
      });
      return true;
    };

    if (window.webkit?.messageHandlers?.latchTokenMessageHandler) {
      this.runningInApp = true;
      this.runningApp = AppType.iOS;
    } else if (typeof latchAndroid !== 'undefined') {
      this.runningInApp = true;
      this.runningApp = AppType.Android;
    }
  }

  getRunningAppType(): AppType {
    return this.runningApp;
  }

  isRunningInApp(): boolean {
    return this.runningInApp;
  }

  shouldHideBackButton(): boolean {
    if (this.runningApp === AppType.iOS) {
      // the presence of this handler implies we're running in the iOS app that supports the native back button
      return !!window.webkit?.messageHandlers?.latchStatusBarMessageHandler;
    } else if (this.runningApp === AppType.Android) {
      return false;
    } else {
      return false;
    }
  }

  getToken(refresh?: boolean): Observable<string> {
    if (this.token && !refresh) {
      return of(this.token);
    }

    if (this.runningApp === AppType.iOS) {
      window.webkit?.messageHandlers?.latchTokenMessageHandler?.postMessage({});
      return this.token$.asObservable().pipe(
        tap(token => {
          this.token = token;
        }),
      );
    } else if (this.runningApp === AppType.Android) {
      return of(latchAndroid.getToken()).pipe(
        tap(token => {
          this.token = token;
        }),
      );
    } else {
      throw new Error('Can\'t get token from native app');
    }
  }

  addAuthentication(request: HttpRequest<unknown>): HttpRequest<unknown> {
    if ((this.runningApp === AppType.iOS || this.runningApp === AppType.Android) && this.token) {
      if (this.token.split('.').length === 3) {
        // the token is a JWT token from auth0
        return request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.token}`,
          },
        });
      } else {
        // the token is a regular token from the native app
        return request.clone({
          setHeaders: {
            access_token: this.token,
          },
        });
      }
    } else {
      return request;
    }
  }

  changeStatusBarColor(color?: StatusBarColor): void {
    if (this.runningApp === AppType.iOS) {
      window.webkit?.messageHandlers?.latchStatusBarMessageHandler?.postMessage({
        color
      });
    }
  }

  openUrl(url: string): void {
    if (this.runningApp === AppType.Web) {
      window.location.href = url;
    } else if (this.runningApp === AppType.iOS) {
      window.webkit?.messageHandlers?.latchExternalUrlMessageHandler?.postMessage({
        url
      });
    } else if (this.runningApp === AppType.Android) {
      latchAndroid.openExternalUrl(url);
    }
  }

  createCheckoutUrl(): string {
    if (this.runningApp === AppType.Web) {
      return `${window.location.origin}/bookings/%s`;
    } else {
      return `${window.location.origin}/redirect?manualredirect=true&r=${environment.appDeepLinkUrl}/bookings?bookings_uuid=%s`;
    }
  }

  logout(): void {
    if (this.runningApp === AppType.iOS) {
      window.location.href = 'latchapp://exit-services';
    } else if (this.runningApp === AppType.Android) {
      window.location.href = `${environment.appDeepLinkUrl}/bookings?result=RESULT_EXIT`;
    }
  }
}
