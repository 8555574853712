import { DOCUMENT } from '@angular/common';
import { Component, Inject, Renderer2 } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { NativeAppService } from './services/native-app.service';

@Component({
  selector: 'latch-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    public auth: AuthService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private nativeApp: NativeAppService,
  ) {
    if (this.nativeApp.shouldHideBackButton()) {
      this.renderer.addClass(this.document.body, 'in-app');
    }
  }
}
