import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@latch/latch-web';
import * as moment from 'moment';
import { Observable, throwError } from 'rxjs';
import {
  BookableResource,
  BookingAppointment,
  BookingStatus,
  Building,
  DayOfWeek,
  DaysOfWeek,
  PaymentType,
  ResourceAppointmentSlotInfo,
  SlotDuration,
  TermsAndConditions,
} from '../model/bookings';
import { mockResponse } from '../utility/mock-response';
import { BookingService, CreateBookingAppointmentInput, SearchBookingsInput } from './booking.service';

const building1: Building = {
  id: 'buildingUUID1',
  name: 'Building 1',
  timezone: 'America/New_York',
};
const building2: Building = {
  id: 'buildingUUID2',
  name: 'Building 2',
  timezone: 'America/New_York',
};
const appointmentUUID1 = 'appointmentUUID1';
const appointmentUUID2 = 'appointmentUUID2';
const appointmentUUID3 = 'appointmentUUID3';
const appointmentUUID4 = 'appointmentUUID4';
const appointmentUUID5 = 'appointmentUUID5';
const appointmentUUID6 = 'appointmentUUID6';
const appointmentUUID7 = 'appointmentUUID7';
const appointmentUUID8 = 'appointmentUUID8';
const appointmentUUID9 = 'appointmentUUID9';
const appointmentUUID10 = 'appointmentUUID10';

const buildings: Building[] = [
  building1,
  building2,
];

const termsAndConditions1: TermsAndConditions = {
  id: 'terms1',
  displayName: '',
  value: 'https://www.latch.com/terms-of-service',
};

const termsAndConditions: TermsAndConditions[] = [
  termsAndConditions1
];

const resourceGym: BookableResource = {
  uuid: 'resourceUUID-gym',
  building: building1,
  name: 'Gym',
  policy: {
    maxSlotsPerPersonPerDay: 3,
    maxAppointmentsPerSlot: 1,
    minLeadDays: 0,
    maxAdvanceDays: 7,
    bookablePeriods: [
      {
        day: DayOfWeek.Monday,
        timeIntervals: [{
          start: 28800000, // 8am
          end: 54000000 // 5pm
        }]
      },
      {
        day: DayOfWeek.Wednesday,
        timeIntervals: [{
          start: 25200000, // 7am
          end: 54000000 // 5pm
        }]
      }
    ],
    slotDuration: SlotDuration.SIXTY
  },
  additionalInformation: 'Due to COVID-19, we have maximum occupancy limit of 5 people. ' +
    'Please keep social distancing and always wear mask while using the amenity room.',
  bookableResourceStats: {
    availableSlots: 0,
    bookedSlots: 0,
    totalAppointments: 45,
    slotAvailableAppointments: 0,
    slotAppointments: 0,
  },
  bookingAmount: {
    paymentType: PaymentType.OneTime,
    timeSlotAmount: 10.00,
  }
};

const resourceYogaRoom: BookableResource = {
  uuid: 'resourceUUID-yoga-Room',
  building: building2,
  name: 'Yoga Room',
  policy: {
    maxSlotsPerPersonPerDay: 2,
    maxAppointmentsPerSlot: 10,
    minLeadDays: 0,
    maxAdvanceDays: 14,
    bookablePeriods: [
      {
        day: DayOfWeek.Monday,
        timeIntervals: [{
          start: 28800000, // 8am
          end: 54000000 // 5pm
        }]
      },
      {
        day: DayOfWeek.Wednesday,
        timeIntervals: [{
          start: 25200000, // 7am
          end: 54000000 // 5pm
        }]
      },
    ],
    slotDuration: SlotDuration.SIXTY,
  },
  additionalInformation: '',
  bookableResourceStats: {
    availableSlots: 0,
    bookedSlots: 0,
    totalAppointments: 12,
    slotAvailableAppointments: 0,
    slotAppointments: 0,
  },
};

const resourceCommunityLounge: BookableResource = {
  uuid: 'resourceUUID-Community-Lounge',
  building: building1,
  name: 'Community Lounge',
  policy: {
    maxSlotsPerPersonPerDay: 2,
    maxAppointmentsPerSlot: 10,
    minLeadDays: 0,
    maxAdvanceDays: 14,
    bookablePeriods: [
      {
        day: DayOfWeek.Monday,
        timeIntervals: [{
          start: 8 * 3600000,
          end: 20 * 3600000
        }]
      },
      {
        day: DayOfWeek.Tuesday,
        timeIntervals: [{
          start: 8 * 3600000,
          end: 20 * 3600000
        }]
      },
      {
        day: DayOfWeek.Wednesday,
        timeIntervals: [{
          start: 8 * 3600000,
          end: 20 * 3600000
        }]
      },
      {
        day: DayOfWeek.Thursday,
        timeIntervals: [{
          start: 8 * 3600000,
          end: 20 * 3600000
        }]
      },
      {
        day: DayOfWeek.Friday,
        timeIntervals: [{
          start: 8 * 3600000,
          end: 20 * 3600000
        }]
      },
      {
        day: DayOfWeek.Saturday,
        timeIntervals: [{
          start: 8 * 3600000,
          end: 17 * 3600000
        }]
      },
      {
        day: DayOfWeek.Sunday,
        timeIntervals: [{
          start: 8 * 3600000,
          end: 17 * 3600000
        }]
      },
    ],
    slotDuration: SlotDuration.SIXTY,
  },
  additionalInformation: '',
  bookableResourceStats: {
    availableSlots: 0,
    bookedSlots: 0,
    totalAppointments: 70,
    slotAvailableAppointments: 0,
    slotAppointments: 0,
  },
  termsAndConditions: termsAndConditions1,
};

const resources: BookableResource[] = [
  resourceGym,
  resourceYogaRoom,
  resourceCommunityLounge,
];

const user: User = {
  uuid: 'mock-user-uuid',
  firstName: 'Jon',
  lastName: 'Smith',
  anonymousUuid: 'anonymous-uuid'
};

const bookings: BookingAppointment[] = [
  {
    uuid: appointmentUUID1,
    bookableResource: resourceGym,
    user: user,
    status: BookingStatus.ACTIVE,
    startTimeEpoch: moment().startOf('hour').add(1, 'hour').unix() * 1000,
    endTimeEpoch: moment().startOf('hour').add(2, 'hour').unix() * 1000,
  },
  {
    uuid: appointmentUUID2,
    bookableResource: resourceGym,
    user: user,
    status: BookingStatus.ACTIVE,
    startTimeEpoch: moment().startOf('hour').add(1, 'day').add(13, 'hours').unix() * 1000,
    endTimeEpoch: moment().startOf('hour').add(1, 'day').add(14, 'hours').unix() * 1000,
  },
  {
    uuid: appointmentUUID3,
    bookableResource: resourceGym,
    user: user,
    status: BookingStatus.ACTIVE,
    startTimeEpoch: moment().startOf('hour').subtract(1, 'day').unix() * 1000,
    endTimeEpoch:   moment().startOf('hour').subtract(1, 'day').add(1, 'hour').unix() * 1000,
  },
  {
    uuid: appointmentUUID4,
    bookableResource: resourceGym,
    user: user,
    status: BookingStatus.REVOKED,
    startTimeEpoch: moment().startOf('hour').subtract(2, 'day').unix() * 1000,
    endTimeEpoch:   moment().startOf('hour').subtract(2, 'day').add(1, 'hour').unix() * 1000,
  },
  {
    uuid: appointmentUUID5,
    bookableResource: resourceYogaRoom,
    user: user,
    status: BookingStatus.ACTIVE,
    startTimeEpoch: moment().startOf('hour').unix() * 1000,
    endTimeEpoch:   moment().startOf('hour').add(1, 'hour').unix() * 1000,
  },
  {
    uuid: appointmentUUID6,
    bookableResource: resourceYogaRoom,
    user: user,
    status: BookingStatus.ACTIVE,
    startTimeEpoch: moment().startOf('hour').unix() * 1000,
    endTimeEpoch:   moment().startOf('hour').add(1, 'hour').unix() * 1000,
  },
  {
    uuid: appointmentUUID7,
    bookableResource: resourceYogaRoom,
    user: user,
    status: BookingStatus.ACTIVE,
    startTimeEpoch: moment().startOf('hour').unix() * 1000,
    endTimeEpoch:   moment().startOf('hour').add(1, 'hour').unix() * 1000,
  },
  {
    uuid: appointmentUUID8,
    bookableResource: resourceGym,
    user: user,
    status: BookingStatus.HOLD,
    startTimeEpoch: moment().startOf('hour').add(2, 'day').add(13, 'hours').unix() * 1000,
    endTimeEpoch: moment().startOf('hour').add(2, 'day').add(14, 'hours').unix() * 1000,
    checkoutUrl: `/bookings/${appointmentUUID8}`,
    checkoutExpireTime: moment().add(10, 'minutes').unix(),
  },
  {
    uuid: appointmentUUID9,
    bookableResource: resourceYogaRoom,
    user: user,
    status: BookingStatus.HOLD,
    startTimeEpoch: moment().startOf('hour').add(2, 'day').add(13, 'hours').unix() * 1000,
    endTimeEpoch: moment().startOf('hour').add(2, 'day').add(14, 'hours').unix() * 1000,
  },
  {
    uuid: appointmentUUID10,
    bookableResource: resourceYogaRoom,
    user: user,
    status: BookingStatus.ACTIVE,
    startTimeEpoch: moment().startOf('day').add(5, 'hours').unix() * 1000,
    endTimeEpoch: moment().startOf('day').add(7, 'hours').unix() * 1000,
  },
];

@Injectable({
  providedIn: 'root'
})
export class MockBookingService implements BookingService {

  constructor(
    private router: Router,
  ) { }

  getBookings(input: SearchBookingsInput): Observable<BookingAppointment[]> {
    return mockResponse(() => bookings
      .map(booking => ({
        ...booking,
        resource: resources.find(resource => resource.uuid === booking.bookableResource?.uuid),
      }))
      .filter(booking => input.searchPastBookings ?
        moment.unix(booking.endTimeEpoch / 1000).isBefore(moment()) :
        moment.unix(booking.endTimeEpoch / 1000).isAfter(moment())
      )
      .filter(booking => {
        if (input.searchTerm) {
          return booking.resource?.name.toLowerCase().includes(input.searchTerm.toLowerCase());
        }
        return true;
      }),
      300
    );
  }

  createBookingAppointments(input: CreateBookingAppointmentInput): Observable<BookingAppointment[]> {
    const uuid = `new-uuid-${Math.random().toString(36).substring(2, 12)}`;
    const newBookings: BookingAppointment[] = input.timeSlots.map(timeSlot => ({
      uuid,
      bookableResource: input.bookableResource,
      user: user,
      status: input.bookableResource.bookingAmount ? BookingStatus.HOLD : BookingStatus.ACTIVE,
      startTimeEpoch: timeSlot.startTimeEpoch,
      endTimeEpoch: timeSlot.endTimeEpoch,
      checkoutUrl: input.bookableResource.bookingAmount ? `/bookings/${uuid}` : undefined,
      checkoutExpireTime: input.bookableResource.bookingAmount ? moment().add(10, 'minutes').unix() : undefined,
    }));
    bookings.push(...newBookings);
    return mockResponse(() => newBookings, 300);
  }

  getBookingAppointment(uuid: string): Observable<BookingAppointment> {
    const booking = bookings.find(b => b.uuid === uuid);
    if (!booking) {
      return throwError(() => new Error('Booking not found'));
    }
    return mockResponse(() => booking, 300);
  }

  deleteBookingAppointment(uuid: string): Observable<null> {
    const booking = bookings.find(b => b.uuid === uuid);
    if (!booking) {
      return throwError(() => new Error('Booking not found'));
    }
    bookings.splice(bookings.indexOf(booking), 1);
    return mockResponse(() => null, 300);
  }

  getResources(searchTerm?: string, availableNow?: boolean): Observable<BookableResource[]> {
    return mockResponse(() => resources
      .filter(resource => {
        if (searchTerm) {
          return resource.name.toLowerCase().includes(searchTerm.toLowerCase());
        }
        return true;
      }),
      300
    );
  }

  getBuildings(searchTerm?: string): Observable<Building[]> {
    if (searchTerm) {
      return mockResponse(() => buildings.filter(building => building.name?.toLowerCase().includes(searchTerm.toLowerCase())), 300);
    } else {
      return mockResponse(() => buildings, 300);
    }
  }

  getResourceTimeSlots(bookableResourceUUID: string, start: number): Observable<ResourceAppointmentSlotInfo> {
    const resource = resources.find(r => r.uuid === bookableResourceUUID);
    if (resource) {
      const dayOfWeek = moment.unix(start).isoWeekday() - 1;
      const slotAvailability = resource.policy.bookablePeriods
        .filter((p) => p.day === DaysOfWeek[dayOfWeek])
        .flatMap(p => p.timeIntervals.flatMap(ti => {
          let slotDurationMs = 0;
          switch (resource.policy.slotDuration) {
            case SlotDuration.THIRTY:
              slotDurationMs = 30 * 60000;
              break;
            case SlotDuration.SIXTY:
              slotDurationMs = 60 * 60000;
              break;
            case SlotDuration.NINETY:
              slotDurationMs = 90 * 60000;
              break;
            case SlotDuration.ONE_TWENTY:
              slotDurationMs = 120 * 60000;
              break;
          }
          const startDay = moment.unix(start).startOf('day');
          const periods = [];
          for (let i = ti.start; i < ti.end; i += slotDurationMs) {
            periods.push({
              start: moment(startDay).add(i, 'ms').valueOf(),
              end: moment(startDay).add(i + slotDurationMs, 'ms').valueOf(),
              appointmentsBooked: 0,
              appointmentsAvailable: resource.policy.maxSlotsPerPersonPerDay,
            });
          }
          return periods;
        }));
      return mockResponse(() => ({
        slotAvailability: slotAvailability,
        slotsBooked: 0,
      }), 300);
    } else {
      return throwError(() => new Error('Resource not found'));
    }
  }

  payBookingAppointment(booking: BookingAppointment): Observable<null> {
    if (booking.checkoutUrl) {
      booking.status = BookingStatus.ACTIVE;
      // refresh component if checkout url is same as current url
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigateByUrl(booking.checkoutUrl ?? '');
      });
    }
    return mockResponse(() => null, 300);
  }

  getTermsAndConditionsDownloadUrl(uuid: string): Observable<string> {
    const termsAndCondition = termsAndConditions.find(t => t.id === uuid);
    if (termsAndCondition) {
      return mockResponse(() => termsAndCondition?.value ?? '', 300);
    } else {
      return throwError(() => new Error('Terms And Condition not found'));
    }
  }
}
