<latch-loading [isActive]="isLoading">
  <latch-card class="summary-card" [class.active]="isActive()" *ngIf="step === Step.Summary">
    <ng-container latchCardHeader>
      <div class="latch-card-actions">
        <button class="latch-button-icon" (click)="back()">
          <span class="latch-icon-back-inverted"></span>
        </button>
      </div>
      <latch-list>
        <div class="latch-card-title" *ngIf="booking?.status === BookingStatus.ACTIVE && !isPast()">Your booking is confirmed.</div>
        <div class="latch-card-title" *ngIf="booking?.status === BookingStatus.ACTIVE && isPast()">Your booking is completed.</div>
        <div class="latch-card-title" *ngIf="booking?.status === BookingStatus.REVOKED">Your booking is revoked.</div>
        <ng-container *ngIf="booking?.status === BookingStatus.HOLD">
          <div class="latch-card-title">Your booking is on hold.</div>
          <div class="body-text" *ngIf="booking?.checkoutExpireTime">Please complete the payment in {{ bookingTimeToExpire(booking?.checkoutExpireTime) }} in order to confirm your booking.</div>
        </ng-container>
        <latch-labeled-list-item label="Booking">
          {{ booking?.bookableResource?.name }}
        </latch-labeled-list-item>
        <div class="latch-divider"></div>
        <latch-labeled-list-item label="Date">
          {{ Date }}
        </latch-labeled-list-item>
        <div class="latch-divider"></div>
      </latch-list>
    </ng-container>
    <ng-container latchCardContent>
      <latch-list>
        <latch-labeled-list-item label="Time">
          {{ Time }}
        </latch-labeled-list-item>
        <ng-container *ngIf="booking?.paymentInfo || booking?.bookableResource?.bookingAmount?.paymentType === PaymentType.OneTime">
          <div class="latch-divider"></div>
          <latch-labeled-list-item label="Price">
            {{ getPaymentDate() }}
          </latch-labeled-list-item>
        </ng-container>
        <ng-container *ngIf="booking?.bookableResource?.additionalInformation">
          <div class="latch-divider"></div>
          <latch-labeled-list-item label="Additional Information">
            {{ booking?.bookableResource?.additionalInformation }}
          </latch-labeled-list-item>
        </ng-container>
      </latch-list>
    </ng-container>
    <ng-container latchCardFooter *ngIf="!isPast()">
      <button class="latch-button latch-dark" *ngIf="booking?.status === BookingStatus.HOLD && booking?.checkoutUrl" (click)="goToPayment()">Continue to payment</button>
      <button class="latch-button-outline latch-dark" *ngIf="enableCalendarLink && (booking?.status === BookingStatus.ACTIVE || booking?.status === BookingStatus.HOLD)" (click)="openCalLink()">
        Add to Calendar
      </button>
      <button class="latch-button-outline latch-danger" *ngIf="booking?.status === BookingStatus.ACTIVE || booking?.status === BookingStatus.HOLD" (click)="cancelBooking()">Cancel booking</button>
      <button class="latch-button latch-dark" *ngIf="booking?.status === BookingStatus.REVOKED" (click)="cancelBooking()">Acknowledge</button>
    </ng-container>
  </latch-card>
  <latch-bookings-terms-and-conditions *ngIf="step === Step.Terms" (back)="back()" (continue)="updateConsent()"></latch-bookings-terms-and-conditions>
</latch-loading>
