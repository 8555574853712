import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserConsentKey, UserConsentAction } from '../model/user';
import { UserConsentResponse, UserService } from './user.service';

interface Result<T> {
  payload: {
    message: T;
  };
}

@Injectable({
  providedIn: 'root'
})
export class HTTPUserService implements UserService {

  constructor(
    private http: HttpClient
  ) { }

  getUserConsent(key: UserConsentKey): Observable<UserConsentResponse> {
    return this.http.get<Result<UserConsentResponse>>(
      `${environment.apiBaseUrl}/app/v1/user/consent/${key}`,
    ).pipe(
      map(result => result.payload.message),
    );
  }

  updateUserConsent(key: UserConsentKey, action: UserConsentAction): Observable<UserConsentResponse> {
    return this.http.post<Result<UserConsentResponse>>(
      `${environment.apiBaseUrl}/app/v1/user/consent/${key}/status/${action}`, {}
    ).pipe(
      map(result => result.payload.message)
    );
  }
}
