import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AuthModule } from '@auth0/auth0-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  LatchCardModule,
  LatchCheckboxModule,
  LatchListModule,
  LatchLoadingModule,
  LatchToggleModule,
} from '@latch/latch-web';
import { BookingsListComponent } from './components/bookings-list/bookings-list.component';
import { BookingsCreateComponent } from './components/bookings-create/bookings-create.component';
import { BookingsPageComponent } from './components/bookings-page/bookings-page.component';
import { environment } from 'src/environments/environment';
import { BookingService } from './services/booking.service';
import { HTTPBookingService } from './services/http-booking.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth/auth.interceptor';
import { MockBookingService } from './services/mock-booking.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LatchExpansionPanelComponent } from './components/expansion-panel/expansion-panel.component';
import { ExpansionPanelHeaderComponent } from './components/expansion-panel/expansion-panel-header/expansion-panel-header.component';
import { BookingsTermsAndConditionsComponent } from './components/bookings-terms-and-conditions/bookings-terms-and-conditions.component';
import { UserService } from './services/user.service';
import { MockUserService } from './services/mock-user.service';
import { HTTPUserService } from './services/http-user.service';
import { RedirectComponent } from './components/redirect/redirect.component';

@NgModule({
  declarations: [
    AppComponent,
    BookingsListComponent,
    BookingsCreateComponent,
    BookingsPageComponent,
    LatchExpansionPanelComponent,
    ExpansionPanelHeaderComponent,
    BookingsTermsAndConditionsComponent,
    RedirectComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    LatchCardModule,
    LatchListModule,
    LatchLoadingModule,
    LatchCheckboxModule,
    LatchToggleModule,
    AuthModule.forRoot(environment.auth0Config),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: BookingService, useClass: environment.useMocks ? MockBookingService : HTTPBookingService },
    { provide: UserService, useClass: environment.useMocks ? MockUserService : HTTPUserService }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
